import { forEach, isNil, isEmpty, some } from 'lodash';
import { createSelector } from 'reselect';
import getTemplateSettingValue from './getTemplateSettingValue';

const getSchema = ({ configData }) => configData.configSchema;
const getConfigs = ({ configData }) => ({
  companyConfig: configData.companyConfig,
  regionalConfig: configData.regionalConfig,
  defaultConfig: configData.defaultConfig,
  groupConfig: configData.groupConfig,
  userConfig: configData.userConfig,
  deletedConfig: configData.deletedConfig,
  modifedConfig: configData.modifiedConfig,
  sideEffectConfig: configData.sideEffectConfig,
  expandedCategories: configData.expandedCategories,
  searchText: configData.searchText,
});
export const getEnvironment = ({ configSelection }) =>
  configSelection.environment;
export const getAuth = ({ auth }) => ({ isTechUser: auth.isTechUser });
export const getSearchResults = ({ configData }) => {
  const { searchResults, configSchema, searchText } = configData;

  return !(searchText === '')
    ? searchResults
    : (configSchema && configSchema.properties) || {};
};

function getArrayTypeForColumnSettings(property) {
  if (Array.isArray(property.anyOf)) {
    const arrayType = property.anyOf.find(schemaType => {
      return schemaType.type === 'array';
    });
    if (arrayType) {
      return arrayType;
    } else {
      return null;
    }
  }
}

export default createSelector(
  [getSchema, getConfigs, getSearchResults, getAuth, getEnvironment],
  (schema, configs, searchResults, auth, deployEnvironment) => {
    if (schema) {
      const { type, properties } = schema;
      const newSchema = {
        type,
        properties: {},
        dependencies: {},
      };
      const {
        defaultConfig,
        deletedConfig,
        regionalConfig,
        companyConfig,
        groupConfig,
        userConfig,
        modifedConfig,
        sideEffectConfig,
        expandedCategories,
        searchText,
      } = configs;

      let mergedConfig = {
        ...defaultConfig,
        ...regionalConfig,
        ...companyConfig,
        ...groupConfig,
        ...userConfig,
        ...modifedConfig,
        ...sideEffectConfig,
      };
      const flatSchema = {};

      forEach(Object.keys(mergedConfig), key => {
        const setting = mergedConfig[key];
        mergedConfig[key] = getTemplateSettingValue(setting, mergedConfig);
      });

      forEach(schema.properties, (property, settingName) => {
        let allowedToChangeSetting = true;
        if (property.devTestOnlyInConfigUI) {
          if (!auth.isTechUser) {
            allowedToChangeSetting = false;
          }
        }
        if (
          property.disable !== true &&
          property.disableInConfigUI !== true &&
          allowedToChangeSetting
        ) {
          let value;
          /* Grid column settings might have the type "Array<WatchlistColumn> | Template;", 
          we need to exctract the Array<WatchlistColumn> part */
          const arrayType = getArrayTypeForColumnSettings(property);
          if (arrayType) {
            property = { ...property, ...arrayType };
          }
          let type = property.type;

          let baseType = type;
          // Used for array settings with a list of possible values
          let items;

          if ((type === 'string' || type === 'number') && property.enum) {
            if (property.selectMapping) {
              items = property.enum.map(value => ({
                label: property.selectMapping[value] || value,
                value,
              }));
            } else {
              items = property.enum.map(value => ({
                label: value,
                value,
              }));
            }
            baseType = 'select';
          } else if (
            type === 'array' &&
            property.items &&
            property.items.enum
          ) {
            items = property.items.enum;
          }

          value = getConfigValue({
            settingName,
            deletedConfig,
            userConfig,
            groupConfig,
            companyConfig,
            defaultConfig,
          });

          let shouldShow = true;
          if (property.conditionallyShow) {
            shouldShow =
              // conditionallyShow = { "Default/foo": true } or { "CAF/Config/tags": ["master","rc","stable"] }
              typeof property.conditionallyShow === 'object'
                ? Object.keys(property.conditionallyShow).every(key =>
                    // Ensure conditionallyShow value is an array to support
                    // multiple values to match against
                    []
                      .concat(property.conditionallyShow[key])
                      .includes(mergedConfig[key])
                  )
                : // conditionallyShow = true
                  !!property.conditionallyShow;
          }

          if (
            shouldShow &&
            property.environmentConditionallyShow &&
            Array.isArray(property.environmentConditionallyShow)
          ) {
            // au-production, au-semiprod, au-uat will match environmentConditionallyShow = ["au"]
            const region = deployEnvironment.key.slice(0, 2);
            shouldShow = property.environmentConditionallyShow.includes(region);
          }

          const payload = {
            ...property,
            baseType: property.overrideType || baseType,
            configDefinedIn: value.configDefinedIn,
            items,
          };

          if (searchResults[settingName] && shouldShow) {
            newSchema.properties[settingName] = payload;
          }
          flatSchema[settingName] = payload;
        }
      });

      let overrideExpand;
      if (searchText) {
        overrideExpand = true;
      }

      const catergorizedSchema = categorize(
        newSchema,
        expandedCategories,
        overrideExpand
      );

      return {
        schema: catergorizedSchema,
        flatSchema,
        mergedConfig,
      };
    }
  }
);

function getConfigValue({
  settingName,
  deletedConfig,
  userConfig,
  groupConfig,
  companyConfig,
  defaultConfig,
}) {
  if (hasOwn(userConfig, settingName)) {
    if (userConfig[settingName] !== undefined && !deletedConfig[settingName]) {
      return {
        configValue: userConfig[settingName],
        configDefinedIn: 'User',
      };
    } else if (groupConfig[settingName] !== undefined) {
      return {
        configValue: groupConfig[settingName],
        configDefinedIn: 'Group',
      };
    } else if (companyConfig[settingName] !== undefined) {
      return {
        configValue: companyConfig[settingName],
        configDefinedIn: 'Company',
      };
    } else {
      return {
        configValue: defaultConfig[settingName],
        configDefinedIn: 'Global',
      };
    }
  } else if (hasOwn(groupConfig, settingName)) {
    if (groupConfig[settingName] !== undefined && !deletedConfig[settingName]) {
      return {
        configValue: groupConfig[settingName],
        configDefinedIn: 'Group',
      };
    } else if (companyConfig[settingName] !== undefined) {
      return {
        configValue: companyConfig[settingName],
        configDefinedIn: 'Company',
      };
    } else {
      return {
        configValue: defaultConfig[settingName],
        configDefinedIn: 'Global',
      };
    }
  } else if (hasOwn(companyConfig, settingName)) {
    if (
      companyConfig[settingName] !== undefined &&
      !deletedConfig[settingName]
    ) {
      return {
        configValue: companyConfig[settingName],
        configDefinedIn: 'Company',
      };
    } else {
      return {
        configValue: defaultConfig[settingName],
        configDefinedIn: 'Global',
      };
    }
  } else if (defaultConfig.hasOwnProperty(settingName)) {
    return {
      configValue: defaultConfig[settingName],
      configDefinedIn: 'Global',
    };
  } else {
    console.warn(
      `Config [${settingName}] cannot be found in Default.json5. Either this is an invalid config and should be taken out of the schema or there is no default value defined in Default.json5`
    );
    return { configValue: undefined, configDefinedIn: 'Undefined' };
  }
}

export function categorize(flatSchema, expandedCategories, overrideExpand) {
  const { properties } = flatSchema;
  const categorizedProperties = Object.keys(properties)
    .filter(key => properties[key].category)
    .reduce((acc, key) => {
      const cat = properties[key].category;
      acc[cat] = acc[cat] || {
        title: cat,
        type: 'object',
        expanded: overrideExpand || expandedCategories[cat],
        properties: {},
      };
      acc[cat].properties[key] = properties[key];

      return acc;
    }, {});

  return {
    type: 'object',
    properties: categorizedProperties,
  };
}

function hasOwn(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}
